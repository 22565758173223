import api from "@/services/api";

import { ClientType } from "@/types/client/Client";

class GetAllClients {
  execute = async () => {
    const client = await api().get(`/customers`);
    return client.data as ClientType[];
  };
}

export { GetAllClients };
