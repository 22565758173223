<template>
  <span class="status" :style="{ color: getFontColor(), backgroundColor: getBackgroundColor() }">
    {{ getText() }}
  </span>
</template>

<script>
export default {
  props: {
    status: {
      type: String,
      required: true,
    },
  },
  methods: {
    getFontColor() {
      switch (this.status) {
        case "ACTIVE":
          return "white";
        case "PAID":
          return "white";
        case "CONFIRMED":
          return "white";
        case "CANCELED":
          return "white";
        case "PENDING":
          return "black";
        case "PRE_REGISTRATION":
          return "black";
        case "PENDENT":
          return "black";
        case "WAITING":
          return "white";
        case "BLOCKED":
          return "white";
        default:
          return "black";
      }
    },
    getBackgroundColor() {
      switch (this.status) {
        case "ACTIVE":
          return "green";
        case "PAID":
          return "green";
        case "CONFIRMED":
          return "green";
        case "CANCELED":
          return "red";
        case "PENDING":
          return "orange";
        case "PRE_REGISTRATION":
          return "orange";
        case "PENDENT":
          return "orange";
        case "WAITING":
          return "blue";
        case "BLOCKED":
          return "black";
        default:
          return "white";
      }
    },
    getText() {
      switch (this.status) {
        case "ACTIVE":
          return "Ativo";
        case "PAID":
          return "Pago";
        case "CONFIRMED":
          return "Confirmado";
        case "CANCELED":
          return "Cancelado";
        case "PENDING":
          return "Pendente";
        case "PENDENT":
          return "Pendente";
        case "PRE_REGISTRATION":
          return "Pré-registrado";
        case "WAITING":
          return "Aguardando";
        case "BLOCKED":
          return "Bloqueado";
        case "WITHDRAWAL":
          return "Saque";
        case "PAYMENT":
          return "Pagamento";
        case "CASHBACK":
          return "Cashback";
        default:
          return "";
      }
    },
  },
};
</script>

<style scoped>
.status {
  padding: 5px 10px;
  border-radius: 10px;
  font-weight: 600;
}
</style>
