import api from "@/services/api";

import { SaleDetailsType } from "@/types/sale/Sale";

class GetQrcode {
  execute = async (sale: SaleDetailsType, expiresIn: number) => {
    const numericString: string = String(sale.value).replace(/[^\d,]/g, "");
    const numericValue: number = parseFloat(numericString.replace(",", "."));

    const qrcode = await api().post(`/transaction`, {
      value: numericValue,
      customerDocument: sale.customerDocument,
      description: "Venda",
      expiresIn: expiresIn,
    });
    return qrcode.data as SaleDetailsType;
  };
}

export { GetQrcode };
