import api from "@/services/api";

import { SaleType } from "@/types/sale/Sale";

class GetAllSales {
  execute = async (startDate: String | undefined, endDate: String | undefined, status: string) => {
    const salesListener = await api().get(`/transactions`, {
      params: {
        startDate: startDate,
        endDate: endDate,
        status: status,
      },
    });

    return salesListener.data as SaleType[];
  };
}

export { GetAllSales };
