<template>
  <v-container fluid class="container-onboarding">
    <div class="header">
      <h3>Solicitação de abertura de conta</h3>
    </div>

    <v-form ref="form" v-model="valid" lazy-validation class="form">
      <p class="subtitle-2 mt-4">Dados principais</p>
      <v-row>
        <v-col cols="12" md="2">
          <v-select
            dark
            :items="person"
            v-model="partner.typeCompany"
            label="Tipo de parceiro"
            :disabled="loading"
            item-text="text"
            item-value="value"
            outlined
          ></v-select>
        </v-col>
        <v-col cols="12" md="5">
          <v-text-field
            dark
            :label="partner.typeCompany == 'PJ' ? 'Razão social *' : 'Nome completo *'"
            :required="true"
            :disabled="loading"
            v-model="partner.name"
            outlined
          />
        </v-col>
        <v-col cols="12" md="5">
          <v-text-field
            dark
            :label="partner.typeCompany == 'PJ' ? 'Nome fantasia' : 'Como quer ser chamado'"
            :disabled="loading"
            v-model="partner.nickName"
            outlined
          />
        </v-col>
        <v-col cols="12" md="3">
          <v-text-field
            dark
            :label="partner.typeCompany == 'PJ' ? 'CNPJ *' : 'CPF * '"
            :disabled="loading"
            :required="true"
            v-model="partner.document"
            outlined
            v-mask="partner.typeCompany !== 'PJ' ? '###.###.###-##' : '##.###.###/####-##'"
          />
        </v-col>
        <v-col cols="12" md="2">
          <v-text-field
            dark
            label="Telefone *"
            :disabled="loading"
            :required="true"
            v-model="partner.phone"
            outlined
            return-masked-value
            v-mask="partner.phone.length <= 14 ? '(##) ####-####' : '(##) #####-####'"
          />
        </v-col>
        <v-col cols="12" md="4">
          <v-text-field
            dark
            label="Email *"
            :disabled="loading"
            :required="true"
            v-model="partner.email"
            outlined
          />
        </v-col>
        <v-col cols="12" md="3">
          <v-autocomplete
            dark
            label="Categoria *"
            outlined
            v-model="partner.categoryId"
            :items="categories"
            :disabled="loading"
            item-text="name"
            item-value="id"
          />
        </v-col>
      </v-row>
      <p class="subtitle-2 mt-4">Dados de endereço</p>
      <v-row>
        <v-col cols="12" md="2">
          <v-text-field
            dark
            label="CEP *"
            :disabled="loading"
            required="true"
            v-model="partner.address.zipCode"
            v-mask="'#####-###'"
            outlined
            @blur="handleAddress"
          />
        </v-col>
        <v-col cols="12" md="4">
          <v-text-field
            dark
            label="Logradouro *"
            :required="true"
            :disabled="loading"
            v-model="partner.address.street"
            outlined
          />
        </v-col>
        <v-col cols="12" md="2">
          <v-text-field
            dark
            label="Número"
            :disabled="loading"
            v-model="partner.address.addressNumber"
            outlined
          />
        </v-col>
        <v-col cols="12" md="4">
          <v-text-field
            dark
            label="Complemento"
            :disabled="loading"
            v-model="partner.address.complement"
            outlined
          />
        </v-col>
        <v-col cols="12" md="4">
          <v-text-field
            dark
            label="Bairro *"
            :required="true"
            :disabled="loading"
            v-model="partner.address.neighborhood"
            outlined
          />
        </v-col>
        <v-col cols="12" md="2">
          <v-text-field
            dark
            label="UF *"
            :required="true"
            :disabled="true"
            :readonly="true"
            v-model="partner.address.state"
            outlined
          />
        </v-col>
        <v-col cols="12" md="4">
          <v-text-field
            dark
            label="Cidade *"
            :required="true"
            :disabled="true"
            :readonly="true"
            v-model="partner.address.cityName"
            outlined
          />
        </v-col>
      </v-row>
      <p class="subtitle-2 mt-4">Dados do usuário</p>
      <v-row>
        <v-col cols="12" md="5">
          <v-text-field
            dark
            outlined
            label="Nome completo *"
            required="true"
            v-model="partner.user.name"
          />
        </v-col>

        <v-col cols="12" md="5">
          <v-text-field
            dark
            outlined
            label="Email *"
            :disabled="loading"
            required="true"
            v-model="partner.user.email"
          />
        </v-col>
        <v-col cols="12" md="3">
          <v-text-field
            dark
            outlined
            label="Celular *"
            @input="handleMaskPhone"
            required="true"
            :disabled="loading"
            v-model="partner.user.phone"
            maxlength="15"
          />
        </v-col>
        <v-col cols="12" md="4">
          <v-text-field
            dark
            outlined
            label="CPF *"
            @input="formatCPF"
            minLength="14"
            maxLength="14"
            :rules="rules"
            :disabled="loading"
            required="true"
            v-model="partner.user.document"
          />
        </v-col>
        <v-col cols="12" md="4">
          <v-text-field
            dark
            outlined
            :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
            :type="show ? 'text' : 'password'"
            required="true"
            v-model="partner.user.password"
            name="input-10-2"
            label="Senha *"
            class="input-group--focused"
            @click:append="show = !show"
          ></v-text-field>
        </v-col>
      </v-row>
    </v-form>

    <v-row class="button-row">
      <v-col cols="6" class="text-left">
        <button-vue @click="$router.push('/login')" type="cancel">Voltar</button-vue>
      </v-col>
      <v-col cols="6" class="text-right">
        <button-vue
          @click="handleSave"
          class="ml-4"
          type="primary"
          :dark="!handleIsFormValid()"
          :disabled="!handleIsFormValid()"
          >Salvar</button-vue
        >
      </v-col>
    </v-row>
    <v-dialog v-model="openDialogAccount" width="600" persistent transition="dialog-top-transition">
      <v-card>
        <v-card-title>Vamos abrir a sua conta EFI?</v-card-title>
        <v-card-text>
          É importante que tenha a conta EFI criada, pois os valores recebidos na suas vendas irão
          para a sua conta EFI em tempo real. É muito fácil e prático.
        </v-card-text>
        <v-card-actions class="d-flex justify-space-between">
          <v-btn elevation="0" text @click="handleLogin"> Não </v-btn>
          <v-btn elevation="0" dark color="#e5183d" @click="handleAccountEFI"> Sim </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import { GetAddress } from "@/services/viaCep/GetAddress";
import { CreatePartner } from "@/services/partners/CreatePartner";
import { GetAllCategories } from "@/services/partners/GetAllCategories";

import ButtonVue from "@/components/button/Button.vue";

import { PartnerDetailsType } from "@/types/partner/Partner";

export default defineComponent({
  components: { ButtonVue },

  data() {
    return {
      rules: [(v: string) => v.length >= 14 || "CPF invalído"],
      currentStep: "mainData",
      stepGroup: "Dados principais",
      hasNotAccount: false,
      openDialogAccount: false,
      valid: true,
      loading: false,
      show: false,
      person: [
        {
          id: 1,
          text: "Pessoa Fisica",
          value: "PF",
        },
        {
          id: 2,
          text: "Pessoa Jurídica",
          value: "PJ",
        },
      ],

      categories: [],
      partner: {
        id: "",
        name: "",
        status: "PENDING",
        nickName: "",
        tax: 5,
        typeCompany: "PJ",
        category: {
          name: "",
          id: "",
        },
        phone: "",
        email: "",
        document: "",
        pixkey: " ",
        cnaeCode: "",
        address: {
          state: "",
          street: "",
          addressNumber: "",
          neighborhood: "",
          complement: "",
          zipCode: "",
          cityName: "",
          ibge: "",
        },
        users: [],
        user: {
          name: "",
          email: "",
          phone: "",
          document: "",
          password: "",
          nickname: "",
          birthdate: "",
          motherName: "",
          backDocumentImage: "",
          frontDocumentImage: "",
          selfImage: "",
        },
        associate: [],
        businessHours: [],
        createdAt: "",
        digitalAccountNumber: "",
        digitalAccountDocument: "",
        associates: [],
        categoryId: "",
        contractFile: "",
        registrationStatusFile: "",
        partnerConfigs: {
          id: 0,
          onlineSale: false,
          pdvSale: false,
          updatedAt: "",
          instantCashback: 0,
        },
      } as PartnerDetailsType,
    };
  },
  methods: {
    handleIsFormValid() {
      return (
        this.handleIsValidMainData() &&
        this.handleIsValidAddressData() &&
        this.handleIsValidUserData()
      );
    },
    handleIsValidMainData() {
      return (
        this.partner.name.length > 0 &&
        this.partner.document.length > 0 &&
        this.partner.phone.length > 0 &&
        this.partner.email.length > 0 &&
        this.partner.categoryId != ""
      );
    },
    handleIsValidAddressData() {
      return (
        this.partner.address.zipCode.length > 0 &&
        this.partner.address.street.length > 0 &&
        this.partner.address.neighborhood.length > 0 &&
        this.partner.address.state.length > 0 &&
        this.partner.address.cityName.length > 0
      );
    },
    handleIsValidUserData() {
      return (
        this.partner.user.name.length > 0 &&
        this.partner.user.email.length > 0 &&
        this.partner.user.phone.length > 0 &&
        this.partner.user.document.length > 0 &&
        this.partner.user.password.length > 0
      );
    },

    async handleAddress() {
      const getAddress = new GetAddress();
      const address = await getAddress.execute(this.partner.address.zipCode);
      if (address) {
        this.partner.address.street = address.logradouro || "";
        this.partner.address.neighborhood = address.bairro || "";
        this.partner.address.cityName = address.localidade || "";
        this.partner.address.state = address.uf || "";
        this.partner.address.ibge = address.ibge || "";
      }
    },
    handleSave() {
      this.loading = true;

      const createPartner = new CreatePartner();
      createPartner
        .execute(this.partner)
        .then(() => {
          this.$store.dispatch("showSnack", {
            message: "Solicitação enviada com sucesso",
            color: "green darken-1",
          });

          this.openDialogAccount = true;
        })
        .catch(() => {
          this.$store.dispatch("showSnack", {
            message: "Erro ao enviar a solicitação",
            color: "red",
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },

    validateNumber() {
      this.partner.digitalAccountNumber = this.partner.digitalAccountNumber.replace(/\D/g, "");
    },

    formatZipCod: (cep: string): string => {
      return cep.replace(/(\d{5})(\d{3})/, "$1-$2");
    },
    formatDocument: (document: string): string => {
      if (document.length === 11) {
        return document.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
      }
      if (document.length === 14) {
        return document.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5");
      }
      return document;
    },

    async handleGetAllCategories() {
      let categories = new GetAllCategories();
      let dataCategories = await categories.execute();

      this.categories = dataCategories.data;
    },

    handleMaskPhone() {
      this.partner.user.phone = this.partner.user.phone.replace(/\D/g, "");
      this.partner.user.phone = this.partner.user.phone
        .replace(/^(\d)/, "($1")
        .replace(/^(\(\d{2})(\d)/, "$1) $2")
        .replace(/(\d{5})(\d{1,5})/, "$1-$2")
        .replace(/(-\d{4})\d+?$/, "$1");
    },
    formatCPF() {
      let cpf = this.partner.user.document.replace(/[^\d]/g, "");

      if (cpf.length > 3) {
        cpf = cpf.substring(0, 3) + "." + cpf.substring(3);
      }
      if (cpf.length > 7) {
        cpf = cpf.substring(0, 7) + "." + cpf.substring(7);
      }
      if (cpf.length > 11) {
        cpf = cpf.substring(0, 11) + "-" + cpf.substring(11, 13);
      }

      this.partner.user.document = cpf;
    },

    handleLogin() {
      this.$router.push("/login");
    },
    handleAccountEFI() {
      window.open("https://sejaefi.com.br/abrir-conta", "_blank");
      this.handleLogin();
    },
  },
  mounted() {
    this.handleGetAllCategories();
  },
});
</script>
<style scoped>
.container-onboarding {
  background: linear-gradient(to bottom, #e5183d, #ee2c4f) !important;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.header {
  color: #fff;
  padding: 16px;
}

.form {
  flex: 1;
  padding: 16px;
}

.button-row {
  display: flex;
  justify-content: center;
  align-items: center;
}

.v-input--has-state > .v-input__control > .v-input__slot > .v-text-field__details > .v-icon {
  color: white !important;
}

.text-efi {
  color: white !important;
}

@media screen and (max-width: 600px) {
  .container-onboarding {
    height: auto;
  }
}

p {
  color: #ffffff;
}
</style>
