import api from "@/services/api";

import { TransactionType } from "@/types/sale/Sale";

class GetTransactionListener {
  execute = async (qrcode: string) => {
    const transaction = await api().get(`/transaction/listener?qrcode=${qrcode}`);
    return transaction.data as TransactionType;
  };
}

export { GetTransactionListener };
