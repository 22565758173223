import { render, staticRenderFns } from "./ClientFormView.vue?vue&type=template&id=3e7db786&scoped=true"
import script from "./ClientFormView.vue?vue&type=script&lang=ts"
export * from "./ClientFormView.vue?vue&type=script&lang=ts"
import style0 from "./ClientFormView.vue?vue&type=style&index=0&id=3e7db786&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3e7db786",
  null
  
)

export default component.exports