import api from "@/services/api";

class GetAddress {
  execute = async (cep: string) => {
    cep = cep ? cep.replace(/\D/g, "") : "";

    const address = await api().get(`https://viacep.com.br/ws/${cep}/json/`);
    return address.data;
  };
}

export { GetAddress };
