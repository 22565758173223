<template>
  <div>
    <v-row class="pa-4 d-none d-md-flex d-md-none">
      <v-col cols="12" md="2">
        <card-resume-vue
          backgroundColor="#5A41F3"
          title1="Total de vendas"
          :title2="sales.length"
        />
      </v-col>
      <v-col cols="12" md="2">
        <card-resume-vue
          backgroundColor="#FFF741"
          title1="Vendas pendentes"
          :title2="handleGetSalesPending()"
        />
      </v-col>
      <v-col cols="12" md="2">
        <card-resume-vue
          backgroundColor="#F3416C"
          title1="Vendas concluídas"
          :title2="handleGetSalesConfirmed()"
        />
      </v-col>
      <v-col cols="12" md="2">
        <card-resume-vue
          backgroundColor="#A89B24"
          title1="Valor bruto de vendas"
          :title2="handleGrossSalesValue()"
        />
      </v-col>
      <v-col cols="12" md="2">
        <card-resume-vue
          backgroundColor="#24A859"
          title1="Valor líquido de vendas"
          :title2="handleRealSalesValue()"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="4">
        <v-dialog ref="dialog" v-model="dialog" persistent width="290px">
          <template v-slot:activator="{ on }">
            <v-text-field
              v-model="handleFormatDate"
              label="Período de vendas"
              prepend-icon="mdi-calendar"
              readonly
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker v-model="selectedRange" range locale="pt-br" actions>
            <v-spacer></v-spacer>
            <v-btn @click="closeDialog" color="primary">Cancelar</v-btn>
            <v-btn @click="submitDates" color="primary">Atualizar</v-btn>
          </v-date-picker>
        </v-dialog>
      </v-col>
      <v-col cols="12" md="3">
        <v-select
          :items="status"
          v-model="statusFiltered"
          item-text="title"
          item-value="value"
          label="Status"
        ></v-select>
      </v-col>
      <v-col cols="12" md="2" align-self="center">
        <v-btn
          :loading="loading"
          :disabled="loading"
          color="#e5183d"
          id="btn-buscar"
          @click="handleLoadSales"
          dark
          block
        >
          <template v-if="loading">
            <v-progress-circular indeterminate color="white" size="24"></v-progress-circular>
          </template>
          <template v-else> Buscar </template>
        </v-btn>
      </v-col>
      <v-col cols="12" md="3" align-self="center" class="text-right">
        <v-btn dark :block="$vuetify.breakpoint.smAndDown" color="#e5183d" @click="handleNewSale()"
          >Nova venda</v-btn
        >
      </v-col>
    </v-row>

    <v-skeleton-loader
      v-if="!!loading"
      tile
      loading
      class="mt-4"
      type="card"
      elevation="{5}"
      boilerplate
    />
    <v-card class="mt-4" v-else>
      <v-card-title>
        <v-row>
          <v-col cols="12">
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Pesquise pelo nome do cliente, CPF, total da venda ou total de repasse"
            />
          </v-col>
        </v-row>
      </v-card-title>
      <v-data-table
        :header-props="{ sortByText: 'Filtrar por' }"
        :headers="headers"
        :items="sales"
        :items-per-page="10"
        :footer-props="{ 'items-per-page-text': 'vendas por página' }"
        :search="search"
        no-data-text="Nenhuma venda encontrada"
        class="elevation-0"
      >
        <template v-slot:body="{ items }">
          <tbody>
            <tr v-for="item in items" :key="item.id">
              <td class="d-block d-sm-table-cell">{{ formatDate(item.createdAt) || "" }}</td>
              <td class="d-block d-sm-table-cell"><status-badge-vue :status="item.status" /></td>
              <td class="d-block d-sm-table-cell">{{ item.customerName || "" }}</td>
              <td class="d-block d-sm-table-cell">
                {{ formatDocument(item.customerDocument || "") }}
              </td>
              <td class="d-block d-sm-table-cell">{{ formatPrice(item.value || "") }}</td>
              <td class="d-block d-sm-table-cell">{{ item.partnerTax }}%</td>
              <td class="d-block d-sm-table-cell">
                {{ formatPrice(item.value - item.value * (item.partnerTax / 100)) }}
              </td>
            </tr>
          </tbody>
        </template>
      </v-data-table>
    </v-card>
    <v-dialog
      v-model="openDialogNotAccount"
      width="600"
      persistent
      transition="dialog-top-transition"
    >
      <v-card>
        <v-card-title><h4>Não é possível continuar a venda!</h4></v-card-title>
        <v-card-text>
          <p class="subtitle-2">
            Não identificamos sua conta EFI no nosso sistema. Deseja incluir o número da conta EFI
            agora?
          </p>
        </v-card-text>
        <v-card-actions class="d-flex justify-space-between">
          <v-btn elevation="0" text @click="openDialogNotAccount = false"> Não </v-btn>
          <v-btn elevation="0" dark color="#e5183d" @click="handleEditPartner()"> Sim </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="openDialogTypeSale" width="600" transition="dialog-top-transition">
      <v-card>
        <v-card-title></v-card-title>
        <v-card-text>
          <h2 class="black--text">Qual tipo de venda irá efetuar?</h2>
        </v-card-text>
        <v-card-actions class="d-flex justify-space-between">
          <v-btn elevation="0" color="green" x-large dark @click="handleNewSaleOnline()">
            <v-icon left>mdi-cart</v-icon> ONLINE
          </v-btn>
          <v-btn elevation="0" dark x-large color="#e5183d" @click="handleNewSalePdv()">
            <v-icon left>mdi-store</v-icon> PDV
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import StatusBadgeVue from "@/components/statusBadge/StatusBadge.vue";
import CardResumeVue from "@/components/cardResume/CardResume.vue";

import { SaleType } from "@/types/sale/Sale";
import { GetAllSales } from "@/services/sales/GetAllSales";
import { GetPartner } from "@/services/partners/GetPartner";
import { format } from "date-fns";

interface SortItem {
  name: string;
  phone: string;
  email: string;
  document: string;
}

export default defineComponent({
  components: { StatusBadgeVue, CardResumeVue },

  data() {
    return {
      mobileBreakpoint: 600,
      selectedRange: [] as string[],
      startDate: "",
      endDate: "",
      firstMoment: true,
      loading: true,
      showModal: false,
      showModalRemove: false,
      sales: [] as SaleType[],
      search: "",
      sortBy: [] as ReadonlyArray<SortItem>,
      sortDesc: false,
      headers: [
        {
          text: "Data e hora da compra",
          value: "createdAt",
        },
        {
          text: "Status",
          value: "status",
          align: "center",
        },
        { text: "Cliente", value: "customerName" },
        { text: "CPF", value: "customerDocument" },
        { text: "Total da venda", value: "value" },
        { text: "Taxa", value: "partnerTax" },
        { text: "Total de repasse", value: "totalValue" },
      ],
      statusFiltered: "CONFIRMED",
      status: [
        {
          title: "Confirmada",
          value: "CONFIRMED",
        },
        {
          title: "Pendente",
          value: "PENDING",
        },
      ],
      hasAccountNumber: false,
      pdvSale: false,
      onlineSale: false,
      idPartner: "",
      openDialogNotAccount: false,
      dialog: false,
      openDialogTypeSale: false,
    };
  },
  methods: {
    async handleLoadSales() {
      this.loading = true;
      const getAllSales = new GetAllSales();
      if (this.selectedRange.length > 0) {
        if (this.selectedRange[0] > this.selectedRange[1]) {
          this.startDate = this.selectedRange[1];
          this.endDate = this.selectedRange[0];
        } else {
          this.startDate = this.selectedRange[0];
          this.endDate = this.selectedRange[1];
        }
      }

      const sales = await getAllSales.execute(this.startDate, this.endDate, this.statusFiltered);

      this.sales = sales.filter((sale: SaleType) => sale.brpointsValue > 0);

      this.loading = false;
    },
    async handleDetailPartner() {
      const getPartner = new GetPartner();
      const partner = await getPartner.execute();
      this.idPartner = partner.id || "";
      if (partner.digitalAccountNumber.length > 0) {
        this.hasAccountNumber = true;
      } else {
        this.hasAccountNumber = false;
      }
      this.onlineSale = partner.partnerConfigs.onlineSale;
      this.pdvSale = partner.partnerConfigs.pdvSale;
    },
    handleEditPartner() {
      this.openDialogNotAccount = false;
      this.$router.push({ name: "MainData" });
    },
    handleNewSalePdv() {
      this.$router.push({
        name: "saleNew",
        params: { modeForm: "new" },
      });
    },
    handleNewSaleOnline() {
      this.$router.push({
        name: "saleOnlineNew",
        params: { modeForm: "new" },
      });
    },
    handleNewSale() {
      if (this.hasAccountNumber) {
        if (this.pdvSale && this.onlineSale) {
          this.openDialogTypeSale = true;
          return;
        }
        if (this.pdvSale && !this.onlineSale) {
          this.handleNewSalePdv();
          return;
        }
        if (!this.pdvSale && this.onlineSale) {
          this.handleNewSaleOnline();
          return;
        }
      } else {
        this.openDialogNotAccount = true;
      }
    },
    handleDetailSale(id: string) {
      this.$router.push(`/sale/form/${id}`);
    },
    phoneNumberFormat: (value: string): string => {
      if (!value) return "";
      const phone = "(" + value.substr(0, 2) + ") " + value.substr(2, 5) + "-" + value.substr(7, 8);
      return phone;
    },
    formatDate: (date: string): string => {
      const dateFormated = format(new Date(date), "dd/MM/yyyy HH:mm:ss");
      return dateFormated;
    },
    formatDocument: (document: string): string => {
      if (document.length === 11) {
        return document.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
      }
      if (document.length === 14) {
        return document.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5");
      }
      return document;
    },
    formatPrice(value: number): string {
      if (!value && value !== 0) return "";
      const formattedValue = value.toFixed(2).replace(".", ",");
      return `R$ ${formattedValue}`;
    },
    handleGetSalesPending() {
      const salesPending = this.sales.filter((sale) => sale.status === "PENDING");
      return salesPending.length;
    },
    handleGetSalesConfirmed() {
      const salesPending = this.sales.filter((sale) => sale.status === "CONFIRMED");
      return salesPending.length;
    },
    handleGrossSalesValue() {
      const salesConfirmed = this.sales.filter((sale) => sale.status === "CONFIRMED");
      const grossSalesValue = salesConfirmed.reduce((total, sale) => total + sale.value, 0);
      return this.formatPrice(grossSalesValue);
    },
    handleRealSalesValue() {
      const salesConfirmed = this.sales.filter((sale) => sale.status === "CONFIRMED");
      const grossSalesValue = salesConfirmed.reduce(
        (total, sale) => total + sale.value - sale.value * (sale.partnerTax / 100),
        0
      );
      return this.formatPrice(grossSalesValue);
    },
    handleInitialDaysInterval() {
      if (this.firstMoment) {
        const today = new Date();
        const thirtyDaysAgo = new Date();
        thirtyDaysAgo.setDate(today.getDate() - 30);
        const formattedToday = today.toISOString().split("T")[0];
        const formattedThirtyDaysAgo = thirtyDaysAgo.toISOString().split("T")[0];
        this.selectedRange = [formattedToday, formattedThirtyDaysAgo];
        this.firstMoment = false;
      }
    },
    closeDialog() {
      this.dialog = false;
    },
    async submitDates() {
      this.dialog = false;
      this.loading = true;
      const getAllSalesListerner = new GetAllSales();
      const executeSales = await getAllSalesListerner.execute(
        this.startDate,
        this.endDate,
        this.statusFiltered
      );
      this.sales = executeSales;
      this.loading = false;
    },
    async handleHasAccountNumber() {
      const getPartner = new GetPartner();
      const partner = await getPartner.execute();
      this.idPartner = partner.id || "";
      if (partner.digitalAccountNumber.length > 0) {
        this.hasAccountNumber = true;
      } else {
        this.hasAccountNumber = false;
      }
    },
  },
  mounted() {
    this.handleInitialDaysInterval();
    this.handleHasAccountNumber();
    this.handleDetailPartner();
    this.handleLoadSales();
  },
  computed: {
    handleFormatDate(): string[] {
      const datesString = this.selectedRange?.map((dates: any) => {
        if (this.selectedRange.length > 0) {
          if (this.selectedRange[0] > this.selectedRange[1]) {
            this.startDate = this.selectedRange[1];
            this.endDate = this.selectedRange[0];
          } else {
            this.startDate = this.selectedRange[0];
            this.endDate = this.selectedRange[1];
          }
        }
        const [year, month, day] = dates.split("-");
        return `${day}/${month}/${year}`;
      });

      return datesString;
    },
  },
});
</script>

<style scoped lang="scss">
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}

#btn-buscar:disabled {
  background: #e5183d !important;
}
#btn-buscar {
  transition: all ease-in-out 0.5s;
}
</style>
