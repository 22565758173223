import { TokenType } from "@/types/user/Token";
import axios from "axios";
import router from "./../router";

const api = () => {
  const axiosInstance = axios.create({
    baseURL: process.env.VUE_APP_API_BASE_URL,
    withCredentials: false,
  });

  axiosInstance.interceptors.request.use(
    (config) => {
      const tokensString = localStorage.getItem("tokens");
      if (tokensString) {
        const tokens = JSON.parse(String(tokensString)) as TokenType;
        config.headers["Authorization"] = `Bearer ${tokens.accessToken}`;
      }

      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  axiosInstance.interceptors.response.use(
    (response) => response,
    async (error) => {
      const originalRequest = error.config;

      if (
        error.response &&
        (error.response.status === 401 || error.response.status === 403) &&
        !originalRequest._retry
      ) {
        originalRequest._retry = true;
        try {
          const tokensString = localStorage.getItem("tokens");
          if (tokensString) {
            const tokens = JSON.parse(String(tokensString)) as TokenType;
            const response = await axios.post(
              `${process.env.VUE_APP_API_BASE_URL}auth/refresh-token`,
              {
                refreshToken: tokens.refreshToken,
              }
            );
            const newTokens = response.data as TokenType;
            localStorage.setItem("tokens", JSON.stringify(newTokens));
            axiosInstance.defaults.headers.common[
              "Authorization"
            ] = `Bearer ${newTokens.accessToken}`;
            originalRequest.headers["Authorization"] = `Bearer ${newTokens.accessToken}`;
            return axiosInstance(originalRequest);
          }
        } catch (refreshError) {
          localStorage.removeItem("tokens");
          return router.push("/login");
        }
      }

      return Promise.reject(error);
    }
  );

  return axiosInstance;
};

export default api;
