<template>
  <v-skeleton-loader
    v-if="!!loading"
    tile
    loading
    class="mx-auto"
    type="card"
    elevation="{5}"
    boilerplate
  />
  <v-container v-else>
    <v-dialog v-model="dialog" width="800">
      <v-card width="800" height="800" class="d-flex align-center">
        <figure v-if="files == null">
          <img
            style="object-fit: cover; width: 100%; height: 100%"
            :src="partner.logoImage"
            :alt="`logotipo da empresa ${partner.name}`"
          />
        </figure>
        <figure v-else>
          <img
            style="object-fit: cover; width: 100%; height: 100%"
            :src="avatarPhoto"
            :alt="`logotipo da empresa ${partner.name}`"
          />
        </figure>
      </v-card>
    </v-dialog>
    <header class="d-flex">
      <v-btn icon small class="mr-3" @click="changeRouter"><v-icon> mdi-arrow-left</v-icon></v-btn>
      <TitleVue title="Editar dados" />
    </header>
    <v-row class="mt-3">
      <v-col cols="12">
        <v-card class="px-5 pt-3">
          <p class="subtitle-2">Logotipo \ Logomarca</p>
          <v-row>
            <v-col cols="12" md="2">
              <v-avatar
                size="117"
                class="cursor-alter"
                v-if="partner.logoImage !== ''"
                @click="dialog = !dialog"
              >
                <figure v-if="files == null">
                  <img
                    style="object-fit: cover; width: 117px; height: 117px"
                    :src="partner.logoImage"
                    :alt="`logotipo da empresa ${partner.name}`"
                  />
                </figure>
                <figure v-else>
                  <img
                    style="object-fit: cover; width: 117px; height: 117px"
                    :src="avatarPhoto"
                    :alt="`logotipo da empresa ${partner.name}`"
                  />
                </figure>
              </v-avatar>
              <v-avatar v-else size="117" color="#e5183d">
                <v-icon color="white" size="117" dark> mdi-account-circle </v-icon>
              </v-avatar>
              <v-file-input
                label="Alterar logo"
                v-model="files"
                @change="handleConvertForBase64"
                accept="image/*"
              />
            </v-col>
          </v-row>
          <p class="subtitle-2">Dados principais</p>
          <v-row>
            <v-col cols="12" md="4">
              <v-text-field
                :label="partner.typeCompany == 'PJ' ? 'Razão social' : 'Nome completo'"
                v-model="partner.name"
              />
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                :label="partner.typeCompany == 'PJ' ? 'Nome fantasia' : 'Como quer ser chamado'"
                v-model="partner.nickname"
              />
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                :label="partner.typeCompany == 'PJ' ? 'CNPJ' : 'CPF'"
                v-model="partner.document"
                v-mask="partner.typeCompany == 'PJ' ? '##.###.###/####-##' : '###.###.###-##'"
              />
            </v-col>
            <v-col cols="12" md="4" v-if="partner.typeCompany === 'PJ'">
              <v-text-field label="CNAE" v-model="partner.cnaeCode" />
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field label="Email" v-model="partner.email" />
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field label="Telefone" v-model="partner.phone" v-mask="'(##) #####-####'" />
            </v-col>
            <v-col cols="12" md="4">
              <v-autocomplete
                label="Categoria"
                :items="categories"
                item-text="name"
                item-value="id"
                v-model="partner.categoryId"
              />
            </v-col>
          </v-row>
          <p class="subtitle-2 mt-4">Dados de endereço</p>
          <v-row>
            <v-col cols="12" md="4">
              <v-text-field
                @blur="handleAddress"
                label="CEP"
                v-model="partner.address.zipCode"
                v-mask="'#####-###'"
              />
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field label="Logradouro" v-model="partner.address.street" />
            </v-col>
            <v-col cols="12" md="2">
              <v-text-field label="Número" v-model="partner.address.addressNumber" />
            </v-col>
            <v-col cols="12" md="3">
              <v-text-field label="Bairro" v-model="partner.address.neighborhood" />
            </v-col>
            <v-col cols="12" md="3">
              <v-text-field label="Complemento" v-model="partner.address.complement" />
            </v-col>

            <v-col cols="12" md="4">
              <v-text-field
                label="Cidade"
                :required="true"
                :disabled="true"
                :readonly="true"
                v-model="partner.address.cityName"
              />
            </v-col>
            <v-col cols="12" md="2">
              <v-text-field
                label="Estado"
                :required="true"
                :disabled="true"
                :readonly="true"
                v-model="partner.address.state"
              />
            </v-col>
          </v-row>
          <p class="subtitle-2 mt-4">Dados bancários</p>
          <v-row>
            <v-col cols="12" md="2">
              <v-text-field label="Documento do titular" v-model="partner.digitalAccountDocument" />
            </v-col>
            <v-col cols="12" md="2">
              <v-text-field label="Número da conta" v-model="partner.digitalAccountNumber" />
            </v-col>
            <v-col cols="12" md="3"
              ><a href="https://sejaefi.com.br/abrir-conta" target="_blank" class="text-efi"
                >Clique para abrir a conta EFI</a
              ></v-col
            >
            <v-col cols="12" md="5">
              <v-text-field label="Chave PIX" v-model="partner.pixkey" />
            </v-col>
          </v-row>
          <v-card-actions class="d-flex justify-end">
            <v-btn text @click="changeRouter">Cancelar</v-btn>
            <v-btn color="#e5183d" class="ml-2" dark width="100" @click="handleUpdatePartner()"
              >Enviar</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="ts">
import TitleVue from "@/components/title/Title.vue";
import { defineComponent } from "vue";
import { PartnerDetailsType } from "@/types/partner/Partner";
import { GetPartner } from "@/services/partners/GetPartner";
import { GetAllCategories } from "@/services/partners/GetAllCategories";
import { UpdatePartner } from "@/services/partners/UpdatePartner";
import { GetAddress } from "@/services/viaCep/GetAddress";
export default defineComponent({
  components: { TitleVue },
  data() {
    return {
      loading: false,
      partner: {
        id: "",
        name: "",
        status: "PENDING",
        nickname: "",
        tax: 5,
        typeCompany: "PJ",
        category: "",
        phone: "",
        email: "",
        document: "",
        pixkey: "",
        logoImage: "",
        cnaeCode: "",
        address: {
          state: "",
          street: "",
          addressNumber: "",
          neighborhood: "",
          complement: "",
          zipCode: "",
          cityName: "",
          ibge: "",
        },
        users: [],
        user: {
          name: "",
          email: "",
          phone: "",
          document: "",
          password: "",
          nickname: "",
          birthdate: "",
          motherName: "",
        },
        associate: [],
        businessHours: [],
        createdAt: "",
        digitalAccountNumber: "",
        digitalAccountDocument: "",
        associates: [],
        categoryId: "",
        contractFile: "",
        registrationStatusFile: "",
        partnerConfigs: {
          id: 0,
          onlineSale: false,
          pdvSale: false,
          instantCashback: 0,
          updatedAt: "",
        },
      } as PartnerDetailsType,
      categories: [],
      files: null,
      avatarPhoto: "",
      dialog: false,
    };
  },
  methods: {
    changeRouter() {
      this.$router.push({ name: "myData" });
    },
    async handleGetDataPartner() {
      this.loading = true;
      const getPartner = new GetPartner();
      const partner = await getPartner.execute();

      partner.document.length > 11
        ? (this.partner.typeCompany = "PJ")
        : (this.partner.typeCompany = "PF");
      this.partner.id = partner.id || "";
      this.partner.name = partner.name || "";
      this.partner.nickName = partner.nickname || "";
      this.partner.document = partner.document || "";
      this.partner.cnaeCode = partner.cnaeCode || "";
      this.partner.category = partner.category || "";
      this.partner.categoryId = partner.categoryId || "";
      this.partner.tax = partner.tax || 0;
      this.partner.status = partner.status;
      this.partner.createdAt = partner.createdAt || "";
      this.partner.digitalAccountDocument = partner.digitalAccountDocument || "";
      this.partner.digitalAccountNumber = partner.digitalAccountNumber || "";
      this.partner.pixkey = partner.pixkey || "";
      this.partner.logoImage = partner.logoImage || "";
      this.partner.phone = partner.phone || "";
      this.partner.email = partner.email || "";
      this.partner.address.street = partner.address.street || "";
      this.partner.address.ibge = partner.address.ibge;
      this.partner.address.addressNumber = partner.address.addressNumber || "";
      this.partner.address.neighborhood = partner.address.neighborhood || "";
      this.partner.address.complement = partner.address.complement || "";
      this.partner.address.zipCode = partner.address.zipCode || "";
      this.partner.address.cityName = partner.address.cityName || "";
      this.partner.address.state = partner.address.state || "";
      this.partner.user = partner.users[0];
      this.partner.associates = partner.associates || [];
      this.partner.businessHours = partner.businessHours || [];
      this.partner.contractFile = partner.contractFile || "";
      this.partner.registrationStatusFile = partner.registrationStatusFile || "";
      this.partner.partnerConfigs.id = partner.partnerConfigs.id || 0;
      this.partner.partnerConfigs.onlineSale = partner.partnerConfigs.onlineSale || false;
      this.partner.partnerConfigs.pdvSale = partner.partnerConfigs.pdvSale || false;
      this.partner.partnerConfigs.updatedAt = partner.partnerConfigs.updatedAt || "";
      this.partner.partnerConfigs.instantCashback = partner.partnerConfigs.instantCashback || 0;
      this.loading = false;
    },
    async handleGetAllCategories() {
      let categories = new GetAllCategories();
      let dataCategories = await categories.execute();

      this.categories = dataCategories.data;
    },
    async handleAddress() {
      const getAddress = new GetAddress();
      const address = await getAddress.execute(this.partner.address.zipCode);
      if (address) {
        this.partner.address.street = address.logradouro;
        this.partner.address.neighborhood = address.bairro;
        this.partner.address.cityName = address.localidade || "";
        this.partner.address.state = address.uf || "";
        this.partner.address.ibge = address.ibge || "";
      }
    },
    handleConvertForBase64() {
      if (this.files) {
        const reader = new FileReader();
        reader.onload = () => {
          let base64Image = reader.result as string;
          base64Image = base64Image.replace(/^data:[^;]+;base64,/, "");
          this.partner.logoImage = base64Image; // Assuming this.partner.logoImage is where you want to store the base64 string
        };
        reader.onerror = (error) => {
          console.error("Error reading the file", error);
        };

        const imageBlob = new Blob([this.files]);
        reader.readAsDataURL(imageBlob);
      }
    },
    handleUpdatePartner() {
      const updatePartnerData = new UpdatePartner();

      updatePartnerData
        .execute(this.partner)
        .then(() => {
          this.$store.dispatch("showSnack", {
            message: "Os dados foram atualizados com sucesso",
            color: "green",
          });
          this.$router.push("/my-data");
        })
        .catch(() => {
          this.$store.dispatch("showSnack", {
            message: "Erro ao atualizar os dados tente novamente mais tarde",
            color: "red",
          });
        });
    },
  },
  mounted() {
    this.handleGetDataPartner();
    this.handleGetAllCategories();
  },
});
</script>

<style scoped></style>
