<template>
  <v-container>
    <v-template>
      <div class="header">
        <title-vue :title="title" />
      </div>

      <v-row class="mt-2">
        <v-col cols="12">
          <v-card>
            <v-card-text>
              <p class="subtitle-2">Dados do cliente</p>
              <v-row>
                <v-col cols="12" md="6">
                  <input-text-vue
                    label="Nome *"
                    :required="true"
                    :disabled="loading"
                    :readonly="readonly"
                    v-model="client.name"
                  />
                </v-col>

                <v-col cols="12" md="3">
                  <input-text-vue
                    label="CPF *"
                    :required="true"
                    :disabled="loading"
                    :readonly="readonly"
                    v-model="client.document"
                    v-mask="'###.###.###-##'"
                  />
                </v-col>
                <v-col cols="12" md="3">
                  <input-text-vue
                    label="Telefone *"
                    :required="true"
                    :disabled="loading"
                    :readonly="readonly"
                    v-model="client.phone"
                    v-mask="'(##) #####-####'"
                  />
                </v-col>
                <v-col cols="12" md="6">
                  <input-text-vue
                    label="Email *"
                    :required="true"
                    :disabled="loading"
                    :readonly="readonly"
                    v-model="client.email"
                  />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-row v-if="!client.id && !loading">
        <v-col class="d-flex justify-end">
          <button-vue dark color="red" class="mr-4" type="cancel" @click="handleCancel"
            >Cancelar</button-vue
          >
          <button-vue dark color="green" type="primary" @click="handleSave">Salvar</button-vue>
        </v-col>
      </v-row>
      <v-row v-else>
        <v-col class="d-flex justify-start">
          <button-vue dark color="red" class="mr-4" type="cancel" @click="handleCancel"
            >Voltar</button-vue
          >
        </v-col>
      </v-row>
    </v-template>
  </v-container>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import ButtonVue from "@/components/button/Button.vue";
import TitleVue from "@/components/title/Title.vue";
import InputTextVue from "@/components/inputs/InputText.vue";

import { CreateClient } from "@/services/clients/CreateClient";
import { GetClient } from "@/services/clients/GetClient";
import { format } from "date-fns";
import { ClientDetailsType } from "@/types/client/Client";

export default defineComponent({
  components: { ButtonVue, TitleVue, InputTextVue },
  data() {
    return {
      loading: false,
      title: "Novo cliente",
      readonly: false,
      client: {
        id: "",
        name: "",
        phone: "",
        email: "",
        document: "",
        createdAt: "",
      } as ClientDetailsType,
    };
  },
  methods: {
    async handleLoadClient() {
      this.readonly = true;
      this.title = "Detalhes do cliente";
      this.loading = true;
      const getClient = new GetClient();
      const client = await getClient.execute(this.$route.params.id);
      this.client.id = client.id || "";
      this.client.name = client.name || "";
      this.client.createdAt = client.createdAt || "";
      this.client.phone = this.formatPhone(client.phone) || "";
      this.client.email = client.email || "";
      this.client.document = this.formatDocument(client.document) || "";

      this.loading = false;
    },
    async handleSave() {
      this.loading = true;
      const createClient = new CreateClient();
      await createClient
        .execute(this.client)
        .then(() => {
          this.$store.dispatch("showSnack", {
            message: "Cliente cadastrado com sucesso",
            color: "green darken-1",
          });
          this.$router.push("/clients");
        })
        .catch(() => {
          this.$store.dispatch("showSnack", {
            message: "Erro ao cadastrar o cliente",
            color: "red",
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    handleCancel() {
      this.$router.push("/clients");
    },

    formatPhone: (value: string): string => {
      if (!value) return "";
      const phone = "(" + value.substr(0, 2) + ") " + value.substr(2, 5) + "-" + value.substr(7, 8);
      return phone;
    },
    formatDate: (date: string): string => {
      const dateFormated = format(new Date(date), "dd/MM/yyyy HH:mm:ss");
      return dateFormated;
    },
    formatZipCod: (cep: string): string => {
      return cep.replace(/(\d{5})(\d{3})/, "$1-$2");
    },
    formatDocument: (document: string): string => {
      return document.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5");
    },
  },
  mounted() {
    if (this.$route.params.id) {
      this.handleLoadClient();
    }
  },
});
</script>
<style scoped>
.label {
  color: #333 !important;
  font-weight: 600;
  margin-bottom: 2px;
  font-size: 16px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}
</style>
