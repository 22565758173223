import api from "@/services/api";
import { ClientDetailsType } from "@/types/client/Client";

class GetClient {
  execute = async (id: string) => {
    const client = await api().get(`/customer/${id}`);
    return client.data as ClientDetailsType;
  };
}

export { GetClient };
