<template>
  <div>
    <v-text-field
      :label="label"
      :required="required"
      :disabled="disabled"
      v-model="internalValue"
      :rules="rules"
      :readonly="readonly"
    />
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  props: ["label", "value", "required", "disabled", "rules", "readonly"],
  data() {
    return {
      internalValue: "",
    };
  },
  watch: {
    internalValue(newValue) {
      this.$emit("input", newValue);
    },
    value(newValue) {
      this.internalValue = newValue;
    },
  },
});
</script>

<style scoped lang="scss"></style>
