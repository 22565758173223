<template>
  <v-btn :disabled="disabled" :dark="dark" :block="block" :class="buttonClass" @click="handleClick">
    <slot></slot>
  </v-btn>
</template>

<script>
export default {
  props: {
    // eslint-disable-next-line vue/require-prop-type-constructor
    dark: true,
    // eslint-disable-next-line vue/require-prop-type-constructor
    block: false,
    // eslint-disable-next-line vue/require-prop-type-constructor
    disabled: false,
    type: {
      type: String,
      default: "default", // Pode ser 'default', 'primary', 'secondary', etc.
    },
  },
  computed: {
    buttonClass() {
      return `button-${this.type}`;
    },
  },
  methods: {
    handleClick() {
      this.$emit("click");
    },
  },
};
</script>

<style scoped>
/* Adicione estilos conforme necessário */
.button-default {
  background-color: #ccc !important;
  color: #333 !important;
}

.button-primary {
  background-color: #e5183d !important;
  color: #fff !important;
}

.button-secondary {
  background-color: #6c757d !important;
  color: #fff !important;
}

.button-approve {
  background-color: green;
  color: #fff !important;
}

.button-repprove {
  background-color: red;
  color: #fff !important;
}

/* Adicione mais estilos conforme necessário */
</style>
