import api from "@/services/api";

class ValidateOtp {
  execute = async (email: string, otp: string) => {
    const validate = await api().post(`/validate-otp`, {
      email: email,
      otp: otp,
    });
    return validate;
  };
}

export { ValidateOtp };
