<template>
  <div>
    <div class="header">
      <v-row class="pa-4">
        <v-col cols="2">
          <card-resume-vue
            backgroundColor="#5A41F3"
            title1="Total de clientes"
            :title2="clients.length"
          />
        </v-col>
        <v-col cols="2">
          <card-resume-vue
            backgroundColor="#24A859"
            title1="Clientes ativos"
            :title2="handleGetClientsActive()"
          />
        </v-col>
        <v-col cols="2">
          <card-resume-vue
            backgroundColor="#FFF741"
            title1="Clientes pendentes"
            :title2="handleGetClientsPending()"
          />
        </v-col>
      </v-row>
      <v-btn dark color="#e5183d" @click="handleNewClient()">Novo cliente</v-btn>
    </div>
    <v-skeleton-loader
      v-if="!!loading"
      tile
      loading
      class="mx-auto"
      type="card"
      elevation="{5}"
      boilerplate
    />
    <v-card v-else>
      <v-card-title>
        <v-row>
          <v-col cols="12">
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Pesquise pelo nome, CPF, telefone ou email"
            />
          </v-col>
        </v-row>
      </v-card-title>
      <v-data-table
        :header-props="{ sortByText: 'Filtrar por' }"
        :headers="headers"
        :items="clients"
        :items-per-page="10"
        :footer-props="{ 'items-per-page-text': 'clientes por página' }"
        :search="search"
        no-data-text="Nenhum cliente encontrado"
        class="elevation-0"
      >
        <template v-slot:body="{ items }">
          <tbody>
            <tr v-for="item in items" :key="item.id">
              <td class="d-block d-sm-table-cell">{{ item.name }}</td>
              <td class="d-block d-sm-table-cell">{{ formatDocument(item.document) }}</td>
              <td class="d-block d-sm-table-cell">{{ phoneNumberFormat(item.phone) }}</td>
              <td class="d-block d-sm-table-cell">{{ item.email }}</td>
              <td class="d-block d-sm-table-cell"><status-badge-vue :status="item.status" /></td>
              <td class="d-block d-sm-table-cell">
                <v-icon size="24" color="#e5183d" @click="handleDetailClient(item.id)"
                  >mdi-menu</v-icon
                >
              </td>
            </tr>
          </tbody>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import StatusBadgeVue from "@/components/statusBadge/StatusBadge.vue";
import CardResumeVue from "@/components/cardResume/CardResume.vue";

import { ClientType } from "@/types/client/Client";
import { GetAllClients } from "@/services/clients/GetAllClients";
import { format } from "date-fns";

interface SortItem {
  name: string;
  phone: string;
  email: string;
  document: string;
}

export default defineComponent({
  components: { StatusBadgeVue, CardResumeVue },

  data() {
    return {
      mobileBreakpoint: 600,
      loading: true,
      showModal: false,
      showModalRemove: false,

      clients: [] as ClientType[],
      search: "",
      sortBy: [] as ReadonlyArray<SortItem>,
      sortDesc: false,
      noDataText: "Nenhum dado disponível",
      headers: [
        {
          text: "Nome",
          value: "name",
        },
        { text: "CPF", value: "document" },
        { text: "Telefone", value: "phone" },
        { text: "Email", value: "email" },
        { text: "Status", value: "status" },
        {
          text: "Ações",
          value: "actions",
        },
      ],
    };
  },
  methods: {
    async handleLoadClients() {
      this.loading = true;
      const getAllClients = new GetAllClients();
      const clients = await getAllClients.execute();

      this.clients = clients;
      this.loading = false;
    },
    handleGetClientsPending() {
      const clientPending = this.clients.filter((client) => client.status === "PENDING");
      return clientPending.length;
    },
    handleGetClientsActive() {
      const clientActive = this.clients.filter((client) => client.status === "ACTIVE");
      return clientActive.length;
    },
    handleNewClient() {
      this.$router.push({
        name: "clientForm",
        params: { modeForm: "new" },
      });
    },
    handleDetailClient(id: string) {
      this.$router.push(`/client/form/${id}`);
    },
    phoneNumberFormat: (value: string): string => {
      if (!value) return "";
      const phone = "(" + value.substr(0, 2) + ") " + value.substr(2, 5) + "-" + value.substr(7, 8);
      return phone;
    },
    formatDate: (date: string): string => {
      const dateFormated = format(new Date(date), "dd/MM/yyyy HH:mm:ss");
      return dateFormated;
    },
    formatDocument: (document: string): string => {
      if (document.length === 11) {
        return document.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
      }
      if (document.length === 14) {
        return document.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5");
      }
      return document;
    },
  },
  mounted() {
    this.handleLoadClients();
  },
});
</script>

<style scoped lang="scss">
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}
</style>
