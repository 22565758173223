import api from "@/services/api";

import { PartnerDetailsType } from "@/types/partner/Partner";
class UpdatePartner {
  execute = async (partner: PartnerDetailsType) => {
    const partnerData = await api().put("/partner", {
      name: partner.name,
      tax: partner.tax,
      phone: partner.phone,
      nickname: partner.nickname,
      email: partner.email,
      document: partner.document,
      pixkey: partner.pixkey,
      logoImage: partner.logoImage,
      typeCompany: partner.typeCompany,
      cnaeCode: partner.cnaeCode || "1",
      category: partner.categoryId,
      address: {
        street: partner.address.street,
        state: partner.address.state,
        number: partner.address.addressNumber,
        neighborhood: partner.address.neighborhood,
        complement: partner.address.complement,
        zipCode: partner.address.zipCode,
        city: partner.address.cityName,
        ibge: partner.address.ibge,
      },
      user: {
        name: partner.user.name,
        motherName: partner.user.motherName,
        nickname: partner.user.email,
        birthdate: partner.user.birthdate,
        email: partner.user.email,
        phone: partner.user.phone,
        document: partner.user.document,
        password: partner.user.password,
      },
      associates: partner.associates,
      businessHours: partner.businessHours,
      digitalAccountNumber: partner.digitalAccountNumber,
      digitalAccountDocument: partner.digitalAccountDocument,
    });

    return partnerData;
  };
}

export { UpdatePartner };
