<template>
  <v-skeleton-loader
    v-if="!!loading"
    tile
    loading
    class="mx-auto"
    type="card"
    elevation="{5}"
    boilerplate
  />
  <v-container v-else>
    <header class="d-flex">
      <TitleVue title="Configurações" />
    </header>

    <v-row class="mt-3">
      <v-col cols="12" md="3">
        <v-checkbox
          v-model="partner.partnerConfigs.onlineSale"
          label="Venda online"
          @change="handleUpdatePartner()"
        ></v-checkbox>
      </v-col>
      <v-col cols="12" md="3">
        <v-checkbox
          v-model="partner.partnerConfigs.pdvSale"
          label="Venda em PDV"
          @change="handleUpdatePartner()"
        ></v-checkbox>
      </v-col>
      <v-col cols="12" md="4" class="d-flex" style="align-items: end">
        <v-slider
          label="Cashback direto"
          class="d-flex align-item-center"
          min="0"
          max="50"
          :disabled="loading"
          v-model="partner.partnerConfigs.instantCashback"
          thumb-color="#FF5726"
          track-fill-color="#FF5726"
          track-color="#FFAF97"
          @change="handleUpdatePartner()"
        >
          <template v-slot:append> {{ partner.partnerConfigs.instantCashback }}% </template>
        </v-slider>
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="ts">
import TitleVue from "@/components/title/Title.vue";
import { defineComponent } from "vue";
import { PartnerDetailsType } from "@/types/partner/Partner";
import { GetPartner } from "@/services/partners/GetPartner";
import { UpdateSettings } from "@/services/settings/UpdateSettings";
export default defineComponent({
  components: { TitleVue },
  data() {
    return {
      loading: false,
      partner: {
        id: "",
        name: "",
        status: "PENDING",
        nickname: "",
        tax: 5,
        typeCompany: "PJ",
        category: "",
        phone: "",
        email: "",
        document: "",
        pixkey: "",
        logoImage: "",
        cnaeCode: "",
        address: {
          state: "",
          street: "",
          addressNumber: "",
          neighborhood: "",
          complement: "",
          zipCode: "",
          cityName: "",
          ibge: "",
        },
        users: [],
        user: {
          name: "",
          email: "",
          phone: "",
          document: "",
          password: "",
          nickname: "",
          birthdate: "",
          motherName: "",
        },
        associate: [],
        businessHours: [],
        createdAt: "",
        digitalAccountNumber: "",
        digitalAccountDocument: "",
        associates: [],
        categoryId: "",
        contractFile: "",
        registrationStatusFile: "",
        partnerConfigs: {
          id: 0,
          onlineSale: false,
          pdvSale: false,
          updatedAt: "",
          instantCashback: 0,
        },
      } as PartnerDetailsType,
      categories: [],
      files: null,
      avatarPhoto: "",
      dialog: false,
    };
  },
  methods: {
    changeRouter() {
      this.$router.push({ name: "myData" });
    },
    async handleGetDataPartner() {
      this.loading = true;
      const getPartner = new GetPartner();
      const partner = await getPartner.execute();

      partner.document.length > 11
        ? (this.partner.typeCompany = "PJ")
        : (this.partner.typeCompany = "PF");
      this.partner.id = partner.id || "";
      this.partner.name = partner.name || "";
      this.partner.nickName = partner.nickname || "";
      this.partner.document = partner.document || "";
      this.partner.cnaeCode = partner.cnaeCode || "";
      this.partner.category = partner.category || "";
      this.partner.categoryId = partner.categoryId || "";
      this.partner.tax = partner.tax || 0;
      this.partner.status = partner.status;
      this.partner.createdAt = partner.createdAt || "";
      this.partner.digitalAccountDocument = partner.digitalAccountDocument || "";
      this.partner.digitalAccountNumber = partner.digitalAccountNumber || "";
      this.partner.pixkey = partner.pixkey || "";
      this.partner.logoImage = partner.logoImage || "";
      this.partner.phone = partner.phone || "";
      this.partner.email = partner.email || "";
      this.partner.address.street = partner.address.street || "";
      this.partner.address.ibge = partner.address.ibge;
      this.partner.address.addressNumber = partner.address.addressNumber || "";
      this.partner.address.neighborhood = partner.address.neighborhood || "";
      this.partner.address.complement = partner.address.complement || "";
      this.partner.address.zipCode = partner.address.zipCode || "";
      this.partner.address.cityName = partner.address.cityName || "";
      this.partner.address.state = partner.address.state || "";
      this.partner.user = partner.users[0];
      this.partner.associates = partner.associates || [];
      this.partner.businessHours = partner.businessHours || [];
      this.partner.contractFile = partner.contractFile || "";
      this.partner.registrationStatusFile = partner.registrationStatusFile || "";
      this.partner.partnerConfigs.id = partner.partnerConfigs.id || 0;
      this.partner.partnerConfigs.onlineSale = partner.partnerConfigs.onlineSale || false;
      this.partner.partnerConfigs.pdvSale = partner.partnerConfigs.pdvSale || false;
      this.partner.partnerConfigs.updatedAt = partner.partnerConfigs.updatedAt || "";
      this.partner.partnerConfigs.instantCashback = partner.partnerConfigs.instantCashback || 0;
      this.loading = false;
    },

    handleUpdatePartner() {
      const updatePartnerData = new UpdateSettings();
      updatePartnerData.execute(this.partner);
    },
  },
  mounted() {
    this.handleGetDataPartner();
  },
});
</script>

<style scoped></style>
