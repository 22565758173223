import api from "@/services/api";

import { PartnerDetailsType } from "@/types/partner/Partner";
class UpdateSettings {
  execute = async (partner: PartnerDetailsType) => {
    const partnerData = await api().put("/partner", {
      partnerConfigs: {
        id: partner.partnerConfigs.id,
        onlineSale: partner.partnerConfigs.onlineSale,
        pdvSale: partner.partnerConfigs.pdvSale,
        instantCashback: partner.partnerConfigs.instantCashback,
      },
    });

    return partnerData;
  };
}

export { UpdateSettings };
