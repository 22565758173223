var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-app',{attrs:{"id":"app"}},[_c('v-dialog',{attrs:{"width":"600","persistent":"","transition":"dialog-top-transition"},model:{value:(_vm.openExit),callback:function ($$v) {_vm.openExit=$$v},expression:"openExit"}},[_c('v-card',[_c('v-card-title'),_c('v-card-text',[_c('h2',{staticClass:"black--text"},[_vm._v("Deseja realmente sair?")])]),_c('v-card-actions',{staticClass:"d-flex justify-space-between"},[_c('v-btn',{attrs:{"elevation":"0","text":""},on:{"click":function($event){_vm.openExit = !_vm.openExit}}},[_vm._v(" Não ")]),_c('v-btn',{attrs:{"elevation":"0","dark":"","color":"#e5183d"},on:{"click":_vm.handleLogout}},[_vm._v(" Sim ")])],1)],1)],1),(
      this.$route.name !== 'login' &&
      this.$route.name !== 'forgotPassword' &&
      this.$route.name !== 'onboarding'
    )?_c('v-navigation-drawer',{staticClass:"custom-gradient no-print",attrs:{"dark":"","app":""},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c('v-list-item',[_c('v-list-item-content',{staticClass:"text-center"},[_c('v-list-item-avatar',{staticClass:"mb-2"},[_c('v-img',{attrs:{"width":"60","height":"50","contain":"","src":_vm.logo,"alt":"LOGO BRP"}})],1),_c('v-list-item-subtitle',[_vm._v(" Parceiros ")]),_c('v-list-item-subtitle',[_vm._v(_vm._s(_vm.name))])],1)],1),_c('v-divider'),_c('v-list',{attrs:{"dense":"","nav":""}},_vm._l((_vm.items),function(item){return _c('v-list-item',{key:item.title,staticClass:"no-print",attrs:{"to":item.to,"link":""},on:{"click":function($event){return _vm.handleChangeTitle(item.title)}}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(item.title))])],1)],1)}),1)],1):_vm._e(),(
      this.$route.name !== 'login' &&
      this.$route.name !== 'forgotPassword' &&
      this.$route.name !== 'onboarding'
    )?_c('v-app-bar',{staticClass:"no-print",attrs:{"app":"","absolute":"","dark":"","color":"#e5183d","height":"66.8px"}},[_c('v-app-bar-nav-icon',{on:{"click":function($event){_vm.drawer = !_vm.drawer}}}),_c('v-toolbar-title',[_vm._v(_vm._s(_vm.title))]),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.openExit = !_vm.openExit}}},[_c('v-icon',[_vm._v("mdi-exit-to-app")])],1)],1):_vm._e(),_c('v-main',[_c('v-snackbar',{attrs:{"color":_vm.snackbar.color,"right":true,"top":true,"timeout":_vm.snackbar.timer},scopedSlots:_vm._u([{key:"action",fn:function({ attrs }){return [_c('v-icon',_vm._b({attrs:{"color":"white"},on:{"click":function($event){_vm.snackbar.active = false}}},'v-icon',attrs,false),[_vm._v("mdi-close")])]}}]),model:{value:(_vm.snackbar.active),callback:function ($$v) {_vm.$set(_vm.snackbar, "active", $$v)},expression:"snackbar.active"}},[_vm._v(" "+_vm._s(_vm.snackbar.message)+" ")]),_c('router-view',{staticClass:"pa-4"})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }