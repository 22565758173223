import api from "@/services/api";

class GetAllCategories {
  execute = async () => {
    const categories = await api().get("/categories");

    return categories;
  };
}

export { GetAllCategories };
