import api from "@/services/api";

import { ClientDetailsType } from "@/types/client/Client";

class CreateClient {
  execute = async (client: ClientDetailsType) => {
    const clientSend = await api().post(`/customer`, {
      name: client.name,
      phone: client.phone,
      email: client.email,
      document: client.document,
    });
    return clientSend.data as ClientDetailsType;
  };
}

export { CreateClient };
