<template>
  <v-container>
    <header class="d-flex justify-space-between no-print">
      <h1>
        <v-btn icon small class="mr-3" @click="changeRouter"><v-icon> mdi-arrow-left</v-icon></v-btn>
        QRCODE
      </h1>
      <div>
        <v-btn elevation="0" fab color="#E5183D" @click="handleGrowSize" dark
          ><v-icon>mdi-plus</v-icon></v-btn
        >
        <v-btn elevation="0" fab color="#E5183D" class="mx-3" @click="handleDecreaseSize" dark
          ><v-icon>mdi-minus</v-icon></v-btn
        >
        <v-btn elevation="0" fab color="#E5183D" @click="handlePrintScreen()" dark
          ><v-icon>mdi-printer-outline</v-icon></v-btn
        >
      </div>
    </header>
    <div
      class="d-flex flex-column justify-center align-center print"
    >
        <div class="d-flex justify-start no-print card-text">
          <h2 class="">Pague com <br /><img :src="logo" width="150" height="100" /></h2>
        </div>
        <p class="text-center text-h4 mt-10 mb-10 text-uppercase white--text print-element">Pague com<br/><span class="text-h2 font-weight-black">BRPOINTS</span><br/>e acumule cashback</p>
        <div style="display: flex; justify-content: center; align-items: center;" class="qrcode">
          <QrCodeVue :value="value" :size="size" class="no-print"/>
          <QrCodeVue :value="value" :size="printSize" class="print-element"/>
        </div>
        <div class="print-element divider mt-4"></div>
        <p class="print-element mt-3 text-h3 text-center text-bold white--text">
          {{ $route.params.name }}
        </p>
        <div class="print-element divider mb-16"></div>
        <p class="font-weight-bold mt-5 no-print card-text" id="text-div">
          Para pagar acesse o app da BRPOINTS, clique em Pague e ganhe e tenha todos os benefícios
        </p>
      </div>
  </v-container>
</template>

<script>
import QrCodeVue from "qrcode.vue";

import Logo from "../../assets/logo-login.png";

export default {
  components: { QrCodeVue },
  data() {
    return {
      value: "",
      size:'450',
      logo: Logo,
      printSize: '350',
      sizeScreen: window.innerWidth
    };
  },
  methods: {
    async handleRequestPartner() {
      this.value = `mode=static&${this.$route.params.doc}&${this.$route.params.name}&${this.$route.params.id}`;

    },
    handlePrintScreen() {
      console.log(this.value)
      window.print();    
    },
    changeRouter() {
      this.$router.push({ name: "myData" });
    },
    handleGrowSize(){
      const convertStringToNumber = Number(this.size)

      const sum = convertStringToNumber + 10

      const convertNumberToString = sum.toString()


      this.size = convertNumberToString
    },
    handleDecreaseSize(){
      if(this.size < 190) return
      const convertStringToNumber = Number(this.size)

      const sum = convertStringToNumber - 10

      const convertNumberToString = sum.toString()


      this.size = convertNumberToString
    }
  },
  created() {
    this.handleRequestPartner();
    console.log(window.innerWidth)
  }
};
</script>

<style scoped>
.card-text{
  width: 450px;
}
.print-element {
  display: none;
}
.qrcode-card{
  width: 450px;
}

@media print {
  .no-print {
    display: none !important;
  }
  .divider {
    width: 600px;
    height: 4px;
    background: #FFF;
  }
  .print-element {
    display: block;
  }
  .print {
    width: 800px;
    height: 900px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px solid red !important;
    background: #e5183d;
    height: auto;
  }
  .qrcode{
    width: 380px;
    height: 380px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #FFF;
    border-radius: 10px;
    margin: 10px 0 40px;
  }
  body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
  }
}
</style>
