import api from "@/services/api";

import { PartnerDetailsType } from "@/types/partner/Partner";

class CreatePartner {
  execute = async (partner: PartnerDetailsType) => {
    const partnerSend = await api().post(`/partner`, {
      name: partner.name,
      tax: partner.tax,
      phone: partner.phone,
      nickName: partner.nickName,
      email: partner.email,
      document: partner.document,
      pixkey: partner.pixkey,
      typeCompany: partner.typeCompany,
      cnaeCode: "1",
      categoryId: partner.categoryId,
      address: {
        street: partner.address.street,
        state: partner.address.state,
        addressNumber: partner.address.addressNumber,
        neighborhood: partner.address.neighborhood,
        complement: partner.address.complement,
        zipCode: partner.address.zipCode,
        cityName: partner.address.cityName,
        ibge: partner.address.ibge,
      },
      user: {
        name: partner.user.name,
        motherName: partner.user.motherName,
        nickname: partner.user.email,
        birthdate: partner.user.birthdate,
        email: partner.user.email,
        phone: partner.user.phone,
        document: partner.user.document,
        password: partner.user.password,
      },
      associates: partner.associates,
      businessHours: partner.businessHours,
      digitalAccountNumber: partner.digitalAccountNumber,
      digitalAccountDocument: partner.document,
      contractFile: partner.contractFile || "PF",
      registrationStatusFile: partner.registrationStatusFile || "PF",
    });
    return partnerSend.data as PartnerDetailsType;
  };
}

export { CreatePartner };
