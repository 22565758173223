<template>
  <div>
    <p class="label">{{ label }}</p>
    <p class="value">{{ value }}</p>
  </div>
</template>

<script>
export default {
  props: ["label", "value"],
};
</script>

<style scoped lang="scss">
.label {
  color: #333 !important;
  font-weight: 600;
  margin-bottom: 2px;
  font-size: 16px;
}
.value {
  color: #1a1a1a !important;
  font-weight: normal;
  font-size: 16px;
}
</style>
