import api from "@/services/api";

import { PartnerDetailsType } from "@/types/partner/Partner";
class GetPartner {
  execute = async () => {
    const partner = await api().get(`/auth/session-info`);
    return partner.data as PartnerDetailsType;
  };
}

export { GetPartner };
